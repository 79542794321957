import { Grid } from "@mui/material";
import React from "react";
import { Routes,Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Footer from "./Components/layout/Footer";
import Header from "./Components/layout/Header";
import Menu from "./Components/NavMenu/Menu";
import Category from "./Components/Category/Category";
import Payout from "./Components/Payout/Payout";
import CustomerReport from "./Components/Reports/CustomerReport";
import SellerReport from "./Components/Reports/SellerReport";
import Tickets from "./Components/Tickets/Tickets";
import { CheckUserIsAuth, CheckUserIsNotAuth } from "./Components/Auth/CheckAuth";
import SignIn from "./Components/Auth/SignIn";
import User from "./Components/User/User";

function App() {
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={2.5}>
            <Menu/>
        </Grid>
        <Grid item xs={9.5}>
          <div style={{backgroundColor:'#f4f6f9'}}>
          <Header/>
              <Routes>
                <Route element={<CheckUserIsAuth />}>
                  <Route path="/user/*" element={<User/>}/>

                  <Route path="/category/*" element={<Category/>}/>

                  <Route path="/" element={<Dashboard/>}/>
                  <Route path="/Dashboard" element={<Dashboard/>}/>
                  <Route path="/SellerReport" element={<SellerReport/>}/>
                  <Route path="/CustomerReport" element={<CustomerReport/>}/>
                  <Route path="/Tickets/*" element={<Tickets/>}/>
                  <Route path="/Payout/*" element={<Payout/>}/>
                  <Route path="/test4" element={<p>You are in the test 4 path</p>}/>
                </Route>
                <Route >
                  <Route path="/signin" element={<SignIn/>}/>
                </Route>
              </Routes>
          <Footer/>
          </div>
        </Grid>
      </Grid>


    </div>
  );
}

export default App;
