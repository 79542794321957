import { createSlice } from "@reduxjs/toolkit";
import jwt from 'jwt-decode'


let initialStateDef = {
    userDetails:
            {
                id:null,
                firstName:null,
                lastName:null,
                email:null,
                phone:null,
                photo:null
,               role:null
            },
    // permissions:[],
        }

const initialState = localStorage.getItem('userData')??JSON.stringify(initialStateDef)

const authSlice = createSlice({
    name:'auth',
    initialState : JSON.parse(initialState),
    reducers:{
        setCredentials: (state,action)=>{
            const userObj = jwt(action.payload);
            state.userDetails['id'] =  userObj.id
            state.userDetails['firstName'] =  userObj.firstName
            state.userDetails['lastName'] =  userObj.lastName
            state.userDetails['email'] =  userObj.email
            state.userDetails['phone'] =  userObj.phone
            state.userDetails['role'] =  userObj.role

            // userObj.privilege.permissions.forEach(permission=>{
            //     state.permissions.push({
            //             resource:permission.resource,
            //             abilities:permission.abilities
            //         })
            // })

            localStorage.setItem('userData',JSON.stringify(state))
            
            
        },
        logOut:(state,action)=>{

            localStorage.removeItem('userData')
            state.userDetails['id'] =  null
            state.userDetails['firstName'] =  null
            state.userDetails['lastName'] =  null
            state.userDetails['email'] =  null
            state.userDetails['phone'] =  null
            state.userDetails['role'] =  null
            // state.permissions.length = 0
            
        }
    }
})

export const {logOut,setCredentials} = authSlice.actions
export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.userDetails
// export const selectCurrentPermission = (state) => state.auth.permissions
