import { Routes,Route } from "react-router-dom";
import React from 'react'
import AddUser from "./AddUser";
import AllUsers from "./AllUsers";
import SearchUser from "./SearchUser";
import VerifiedUsers from "./SearchUser";
import SingleUser from "./SingleUser";


function User() {
  return (
    <Routes>
        <Route path="/add" element={<AddUser/>}/>
        <Route path="/all" element={<AllUsers/>}/>
        <Route path="/all/:userId" element={<SingleUser/>}/>
        <Route path="/search" element={<SearchUser/>}/>
    </Routes>
  )
}

export default User
