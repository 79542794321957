import React, { useEffect } from 'react'
import './Order.css'
import { useAllCategoriesQuery, useSingleCateQuery} from '../../api/endpoints/Category';
import {RiDeleteBinFill} from 'react-icons/ri'
import {HiArrowsExpand} from 'react-icons/hi'
import { Modal,Table,TableBody,TableCell,TableContainer,TableHead,TableRow } from '@mui/material';
import { useState } from 'react';
import { Spinner } from '../Other/Loading/Spinner';
import { DeleteCategory } from './DeleteCategory';
import { SingleCate } from './SingleCate';

const modalDiv = {
    position:"absolute",
    width:"450px",
    height:"fit-content",
    backgroundColor:"white",
    outline:"none",
    top:"10%",
    left:"40%",
    padding:"10px"
}



function Categories() {
    const [categories, setCategories] = React.useState([]); 
    const {data,isLoading,error} = useAllCategoriesQuery()

    useEffect(()=>{
        if(data?.code == "SUCCESS"){
            setCategories(data?.result?.items)
        }
    },[data])

    const [editModal,setEditModal] = useState(false)
    const [deleteModal,setDeleteModal] = useState(false)
    const [currCate,setCurrCate] = useState(null)


    const handleEditModal = (cateId)=>{
        setCurrCate(cateId)
        setEditModal(prev=>!prev)
    }
    const handleDeleteModal = (cateId)=>{
        setCurrCate(cateId)
        setDeleteModal(prev=>!prev)
    }

  return (
    <div className='tableContainer'>
        <TableContainer>
            <Table>
                <TableHead >
                    <TableRow>
                        <TableCell><p>Image</p></TableCell>
                        <TableCell><p>ID</p></TableCell>
                        <TableCell><p>Title</p></TableCell>
                        <TableCell><p>Description</p></TableCell>
                        <TableCell><p>Created By</p></TableCell>
                        <TableCell><p>Actions</p></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {categories.map((cate,index)=>(
                        <TableRow key={index}>
                            <TableCell><img src={cate.icon} alt="" /></TableCell>
                            <TableCell><p>{cate.id}</p></TableCell>
                            <TableCell><p>{cate.title}</p></TableCell>
                            <TableCell><p>{cate.description}</p></TableCell>
                            <TableCell><p>{cate.createdBy.email}</p></TableCell>
                            <TableCell>
                                <p className='flex-Arn'>
                                    <HiArrowsExpand onClick={()=>handleEditModal(cate.id)} size={25} color='#05c279'/>
                                    <RiDeleteBinFill onClick={()=>handleDeleteModal(cate.id)} size={25} color='#ED2939'/>
                                </p>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div>
                    <EditCategory isOpen={editModal} CategoryId={currCate} setEditModal={setEditModal}/>
                    <DeleteCategory isOpen={deleteModal} CategoryId={currCate} setDeleteModal={setDeleteModal}/>
        </div>
    </div>
  )
}

const EditCategory =({isOpen,CategoryId,setEditModal}) =>{
    const {data,isLoading,error} = useSingleCateQuery(CategoryId)
    const [cate,setCate] = useState()
    useEffect(()=>{
        if(data?.code == "SUCCESS"){
            setCate(data?.result)
        }else{
            
        }
    },[data])
    const handleClose = () =>{
        setEditModal(prev=>!prev)
    }
    return(
        <Modal open={isOpen} onClose={handleClose}>
            <div style={modalDiv}>
                {isLoading?(
                        <Spinner />
                    ):(
                        <SingleCate result={data?.result} cateId={CategoryId}/>
                    )}
            </div>   
        </Modal>
    )
}






export default Categories
