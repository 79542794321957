import { Route, Routes } from "react-router-dom"
import Categories from "./Categories"
import AddCategory from "./AddCategory"



function Category() {
    return (
      <Routes>
          <Route path="/" element={<Categories/>}/>
          <Route path="/add" element={<AddCategory/>}/>
      </Routes>
    )
  }

  export default Category
