import React from 'react'
import { useParams } from 'react-router-dom'

function SingleOpenTicket() {
    let { ticketId } = useParams();
  return (
    <div className='tableContainer'>
        <div style={{padding:'20px'}}>
            <p className='ticket_author'>Ticket author : loremLorem@gmail.com</p>
            <p className='ticket_title'>Title :<b>lorem Does not work very well</b></p>
                <h2>Issue Description</h2>
            <p className='ticket_desc'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde vel soluta totam perspiciatis excepturi! Ducimus inventore sunt possimus sed illum voluptates architecto unde, sequi cumque eius reprehenderit autem eveniet quas incidunt quidem nobis quia pariatur non excepturi, error reiciendis veritatis tenetur aliquam veniam. Et, explicabo quaerat eum a repudiandae reprehenderit!
            </p>
<label htmlFor="assign">Assign To</label><br />
            <select name="assign" id="assign">
                <option value="lorem0@gmail.com">lorem0@gmail.com</option>
                <option value="lorem1@gmail.com">lorem1@gmail.com</option>
                <option value="lorem2@gmail.com">lorem2@gmail.com</option>
                <option value="lorem3@gmail.com">lorem3@gmail.com</option>
                <option value="lorem4@gmail.com">lorem4@gmail.com</option>
                <option value="lorem5@gmail.com">lorem5@gmail.com</option>
            </select>
        </div>
    </div>
  )
}

export default SingleOpenTicket
