import { toast } from 'react-toastify';
import { useEditCategoryMutation } from '../../api/endpoints/Category';
import { Spinner } from '../Other/Loading/Spinner';
import { Button,Modal } from '@mui/material';
import { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
  categoryId:yup.number(),
  title: yup.string(),
  description: yup.string(),
  icon: yup.mixed()
}).required();

export const SingleCate =({result,cateId}) =>{
    const [currCate,setCurrCate] = useState()
    const [currIsLoading, setCurrIsLoading] = useState(true)
    const [editCate, {data,isLoading}] = useEditCategoryMutation()
    const { register, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
      });
      const onSubmit = async (data,event)=>{
        editCate(data)
    }
    useEffect(()=>{
        if(cateId == result?.id){
            setCurrIsLoading(false)
            setCurrCate(result)
        }
    },[result])
    useEffect(()=>{
        if(data?.code == "SUCCESS"){
          toast.success('Category modified with Success !',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
          window.location.reload();
        }else if(data?.status == "FAILURE"){
          toast.error('Category modification Failed !',{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
        }
      },[data])
    return(
        <div className='singleCategory'>
            {
                currIsLoading?(
                    <Spinner />
                ):(
                    <form onSubmit={handleSubmit(onSubmit)}>
                <input type="number" id='categoryId' hidden defaultValue={currCate?.id} {...register("categoryId")}/>
                <div>
                    <label htmlFor="title">Category Title {currCate?.id}</label>
                    <input type='text' id='title' defaultValue={currCate?.title} {...register("title")}/>
                </div>
                <div>
                    <label htmlFor="title">Category Description</label>
                    <textarea defaultValue={currCate?.description} name="" id="" cols="10" rows="4" {...register("description")}></textarea>
                </div>
                {
              isLoading?(
                <Spinner />
              ):(
                <Button fullWidth type="submit">Save Modifications</Button>
              )
            }
            </form>
                )
            }
        </div>
    )
}
