import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";
// import authReducer from "../features/auth/authSlice";
// import { apiSlice } from "./api/apiSlice";
// apiSlice
import authReducer from "../authSlice";



export const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]:apiSlice.reducer,
        auth:authReducer
    }, 
    middleware:getDefaultMiddleware =>
        getDefaultMiddleware({serializableCheck:false}).concat(apiSlice.middleware),
    devTools:false
})
