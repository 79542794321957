import {FaMoneyBill} from 'react-icons/fa'
import {IoIosPeople} from 'react-icons/io'
import {FaBook} from 'react-icons/fa'
import {AiFillPicture} from 'react-icons/ai'
import {BsFillClockFill} from 'react-icons/bs'
import {SiHeadspace} from 'react-icons/si'
import {IoPlanet} from 'react-icons/io5'
import React from 'react'
import Block from '../Other/DashboardBlock/Block'
import { Grid } from '@mui/material'
import './Dashboard.css'
import Earnings from './Earnings'
import LocationStats from './LocationStats'
import LatestAds from './LatestAds'
import LatestRegisteredUsers from './LatestRegisteredUsers'
import LatestTransactions from './LatestTransactions'

function Dashboard() {
    const blocks = [
        {name:"Earnings",amount:"$ 11450",bgColor:"#28a745",icon:<FaMoneyBill size={40}/>},
        {name:"Total Customers",amount:"43",bgColor:"#28a745",icon:<IoIosPeople size={40}/>},
        {name:"Verified Customers",amount:"43",bgColor:"#6c757d",icon:<FaBook size={40}/>},
        {name:"Total BlogPost",amount:"43",bgColor:"#05c279",icon:<AiFillPicture size={40}/>},
        {name:"Total Ads",amount:"43",bgColor:"#17a2b8",icon:<BsFillClockFill size={40}/>},
        {name:"Pending Ads",amount:"43",bgColor:"#dc3545",icon:<SiHeadspace size={40}/>},
        {name:"Active Ads",amount:"43",bgColor:"#28a745",icon:<IoPlanet size={40}/>},
        {name:"Sold ads",amount:"43",bgColor:"#dc3545",icon:<IoIosPeople size={40}/>},
        {name:"Featured ads",amount:"43",bgColor:"#05c279",icon:<IoIosPeople size={40}/>},
        {name:"Total Country",amount:"43",bgColor:"#6c757d",icon:<IoIosPeople size={40}/>},
        {name:"Total Plans",amount:"43",bgColor:"#17a2b8",icon:<IoIosPeople size={40}/>}
    ]
  return (
    <div className='Dashboard-container'>
        <div> {/* Block stats Container */}
            <p className='dashboard-text'>Dashboard</p>
            <Grid container spacing={2}>
                {blocks.map(block=>{
                    return(
                        <Grid item xs={3}>
                            <Block name={block.name} bgColor={block.bgColor} icon={block.icon} amount={block.amount}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
        <div className='Earnings-Location-stats'> {/*Earning and Location Stats Container*/}
            <Grid container spacing={2}>
            <Grid item xs={6}>
                    <Earnings/>
                </Grid>
                <Grid item xs={6}>
                    <LocationStats/>
                </Grid>
            </Grid>
        </div>
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LatestAds />
                </Grid>
                <Grid item xs={6}>
                    <LatestRegisteredUsers />
                </Grid>
                <Grid item xs={12}>
                    <LatestTransactions />
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Dashboard
