import {apiSlice} from "../apiSlice"

const authEndpoints = apiSlice.injectEndpoints({
    endpoints:builder =>({
        login: builder.mutation({
            query:credentials => ({
                url:'https://dev.auth.api.fairlyx.com/users/login',
                method:'POST',
                body:credentials, 
            })
        }),
        allPrivileges:builder.query({
            query:()=>('https://dev.auth.api.fairlyx.com/privileges')
        })
    })

})

export const {
    useLoginMutation,
    useAllPrivilegesQuery
        } = authEndpoints
