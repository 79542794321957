import './layout.css'
import {BsPersonCircle} from 'react-icons/bs'
import {BsFillExclamationSquareFill} from 'react-icons/bs'
import {FiLogOut} from 'react-icons/fi'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logOut, selectCurrentUser } from '../../api/authSlice'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function Header() {
  const user = useSelector(selectCurrentUser)

  return (
    <div>
      <div className='header-div'>
        {user?.email?.length>5?(
          <LoggedIn user = {user} />
        ):(
          <NotLoggedIn />
        )}
      </div>
    </div>
  )
}


const LoggedIn = ({user})=>{
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const dispatch = useDispatch()
  const navigate =  useNavigate()

  const handleLogout = () =>{
    dispatch(logOut())
    removeCookie('token','',{secure:true})
    navigate('/signin')
  }

  return (
    <div className="header-inner">
          <div className='header-user-info'>
            <BsPersonCircle size={35} />
            <p>{user?.email}</p>
          </div>
          <div className='header-user-info' onClick={handleLogout} style={{cursor:"pointer"}}>
            <FiLogOut size={30} />
            <p>Logout</p>
          </div>
        </div>
  )
}

const NotLoggedIn = () =>{
  return(
    <div className="header-inner">
          <div className='header-user-info'>
            <BsFillExclamationSquareFill size={35} />
            <p>Login</p>
          </div>
          <div className='header-user-info'>
            <h3>Fairlyx Admin Page</h3>
          </div>
        </div>
  )
}

export default Header
