import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchUserQuery } from '../../api/endpoints/User'
import { Spinner } from '../Other/Loading/Spinner'
import { Link } from 'react-router-dom'
import { FaExpandArrowsAlt } from 'react-icons/fa'

function SearchUser() {
  const [term,setSearchTerm] = useState('')
  const {data,isLoading,error} = useSearchUserQuery(term)
  const [found,setFound] = useState([])

  useEffect(()=>{
    setTimeout(()=>{
      if(data?.code == "SUCCESS"){
        setFound(data?.result?.items)
    }},1000)
  },[data])
  useEffect(()=>{
    if(term.length == 0){
      setFound([])
    }
  })

  return (
    <div>
      <div className='singleUserContainer' style={{backgroundColor:"transparent"}}>
        <label htmlFor="searchTerm">Enter user name</label>
        <input type='text' id='searchTerm' value={term} onChange={e=>setSearchTerm(e.target.value)}/>
      </div>
      <div>
        <div className='tableContainer'>
        <TableContainer>
            <Table>
                <TableHead >
                    <TableRow>
                        <TableCell><p>ID</p></TableCell>
                        <TableCell><p>Email</p></TableCell>
                        <TableCell><p>First name</p></TableCell>
                        <TableCell><p>Last name</p></TableCell>
                        <TableCell><p>Role</p></TableCell>
                        <TableCell><p>Priviledge</p></TableCell>
                        <TableCell><p>View</p></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading?(
                      <p style={{with:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Spinner />
                      </p>
                    ):(
                      found.map((user,index)=>(
                        <TableRow key={index}>
                            <TableCell><p>{user.id}</p></TableCell>
                            <TableCell><p>{user.email}</p></TableCell>
                            <TableCell><p>{user.firstName}</p></TableCell>
                            <TableCell><p>{user.lastName}</p></TableCell>
                            <TableCell><p>{user.role}</p></TableCell>
                            <TableCell><p>{user?.privilege?.name}</p></TableCell>
                            <TableCell>
                              <Link to={`/user/all/${user.id}`}><FaExpandArrowsAlt color='#05c279' size={20}/></Link>
                            </TableCell>
                        </TableRow>
                    ))
                    )}
                  </TableBody>
            </Table>
        </TableContainer>
        </div>
      </div>
    </div>
  )
}

export default SearchUser
