import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAllPrivilegesQuery } from '../../api/endpoints/Auth';
import { Button, Grid } from '@mui/material';
import { useAddUserMutation } from '../../api/endpoints/User';
import { Spinner } from '../Other/Loading/Spinner';
import {toast} from 'react-toastify'


const schema = yup.object({
  firstName: yup.string().required('*Enter Firstname'),
  lastName: yup.string().required('*Enter Lastname'),
  country: yup.string().required('*Enter Country'),
  address: yup.string().required('*Enter Address'),
  phone: yup.string().required('*Enter telephone'),
  email: yup.string().required('*Enter Email'),
  enabled:yup.boolean().required('*Select').typeError("*"),
  role:yup.string().required("*").typeError("*"),
  privilegeId:yup.number().required('*').typeError("*"),
}).required();

function AddUser() {
  const [err,setErr] = useState('')
  const [privs,setPrivs]= useState([]);
  const {data} = useAllPrivilegesQuery()
  const [addUser,{isLoading,data:data2,error}] = useAddUserMutation()

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data3,event)=>{
    const results = await addUser(data3).unwrap()
    }
  useEffect(()=>{
    if(data?.status === "SUCCESS"){
      setPrivs(data?.result?.items)
    }
  },[data])

useEffect(()=>{
  if(data2?.code == "USER_CREATED"){
    setErr('')
    toast.success('User Succesfully created !',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    })
  }else if(data2?.status == "FAILURE"){
    setErr(data2?.error)
    toast.error('User Creation Failed !',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    })
  }
},[data2])

  return (
    <div>
        <div className='formContainer'>
        <h4 className='center-text error'>{err}</h4>
          <form onSubmit={handleSubmit(onSubmit)} key={1} >
            <Grid container columnSpacing={7}>
              <Grid item xl={6} lg={6} md={6}>
                <div>
                  <label htmlFor="firstName">Firstname</label>
                  <input type="text" {...register("firstName")}/>
                  {<span className='error'>{errors.firstName?.message}</span>}
                </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <div>
                  <label htmlFor="lastName">Lastname</label>
                  <input type="text" {...register("lastName")}/>
                  {<span className='error'>{errors.lastName?.message}</span>}
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
              <div>
                <label htmlFor="country">Country</label>
                <input type="text" {...register("country")}/>
                {<span className='error'>{errors.country?.message}</span>}
              </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <div>
                  <label htmlFor="address">Address</label>
                  <input type="text" {...register("address")}/>
                  {<span className='error'>{errors.address?.message}</span>}
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
              <div>
                <label htmlFor="email">Email</label>
                <input type="email" {...register("email")}/>
                {<span className='error'>{errors.email?.message}</span>}
              </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <div>
                  <label htmlFor="phone">Telephone</label>
                  <input type="text" {...register("phone")}/>
                  {<span className='error'>{errors.phone?.message}</span>}
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4}>
              <div>
                <label htmlFor="role">Role</label>
                  <select {...register("role")}>
                    <option value="">Select</option>
                    <option value="ADMIN">Admin</option>
                    <option value="SUPER_ADMIN">Super Admin</option>
                  </select>
                  {<span className='error'>{errors.role?.message}</span>}
              </div>
                </Grid>
                <Grid item xl={3} lg={3} md={3}>
                <div>
                <label htmlFor="enable">Enabled ?</label>
                  <select {...register("enabled")}>
                    <option value="">Select</option>
                    <option value={true}>yes</option>
                    <option value={false}>No</option>
                  </select>
                  {<span className='error'>{errors.enabled?.message}</span>}
              </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4}>
              <div>
                <label htmlFor="role">Privilege</label>
                  <select {...register("privilegeId")}>
                    <option value="">Select</option>
                    {privs.map((priv,index)=>{
                      return(
                        <option key={index} value={priv.id}>{priv.name}</option>
                      )
                    })}
                  </select>
                  {<span className='error'>{errors.privilegeId?.message}</span>}
              </div>
              </Grid>
            </Grid>
            {/* <button type="submit">Add User</button> */}
            {
              isLoading?(
                <Spinner />
              ):(
                <Button fullWidth type="submit">Add user</Button>
              )
            }
          </form>
        </div>
    </div>
  )
}

export default AddUser
