import CircularProgress from '@mui/material/CircularProgress';



export const Spinner =()=>{
    return (
        <div style={{display:'flex',justifyContent:'space-around',marginTop:'45px'}}>
            <CircularProgress/>
        </div>
    )
}

export function formatDate(dateString){
    const date = new Date(dateString);
    console.log(date)
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC'
      };

      return new Intl.DateTimeFormat('en-US', options).format(date)
}
