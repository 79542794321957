import { Routes,Route } from "react-router-dom";
import React from 'react'
import TodayAccepted from "./TodayAccepted";
import Pending from "./Pending";
import MonthAccepted from "./MonthAccepted";
import MonthCharges from "./MonthCharges";

function Payout() {
  return (
    <Routes>
        <Route path="/today" element={<TodayAccepted/>}/>
        <Route path="/pending" element={<Pending />}/>
        <Route path="/monthAccepted" element={<MonthAccepted/>}/>
        <Route path="/monthCharges" element={<MonthCharges/>}/>
    </Routes>
  )
}

export default Payout
