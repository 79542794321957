import {apiSlice} from "../apiSlice"

const userEndpoints = apiSlice.injectEndpoints({
    endpoints:builder =>({
        addUser: builder.mutation({
            invalidatesTags:['Users'],
            query:credentials => ({
                url:'https://dev.auth.api.fairlyx.com/users/add',
                method:'POST',
                body:credentials, 
            })
        }),
        getUsers : builder.query({
            providesTags:['Users'],
            query:(type)=>(`https://dev.auth.api.fairlyx.com/users/list/${type}`)
        }),
        getSingleUser :builder.query({
            query:(userId)=>(`https://dev.auth.api.fairlyx.com/users/${userId}`)
        }),
        searchUser:builder.query({
            query:(searchTerm)=>(`https://dev.auth.api.fairlyx.com/users/search/${searchTerm}`)
        })
    })

})

export const {
    useAddUserMutation,
    useGetUsersQuery,
    useGetSingleUserQuery,
    useSearchUserQuery
} = userEndpoints
