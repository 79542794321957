import './block.css'

import React from 'react'

function Block({name,amount,bgColor,icon}) {
  return (
    <div className='dashboard-block-single'>
        <div style={{backgroundColor:bgColor}} className='dashboard-block-icon'>
{icon}
        </div>
        <div className='dashboard-block-text'>
<p>{name}</p>
<p><b>{amount}</b></p>
        </div>
    </div>
  )
}

export default Block
