import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAllPrivilegesQuery } from '../../api/endpoints/Auth';
import { Button, Grid } from '@mui/material';

import { Spinner } from '../Other/Loading/Spinner';
import {toast} from 'react-toastify'
import { useAddCategoryMutation } from '../../api/endpoints/Category';


const schema = yup.object({
  title: yup.string().required('*Enter title'),
  symbol: yup.string().required('*Enter Symbol'),
  description: yup.string().required('*Enter description'),
  currency: yup.string().required('*Enter Currency'),
  icon: yup.mixed()

}).required();

function AddCategory() {
  const [err,setErr] = useState('')
  const [addCategory,{isLoading,data,error}] = useAddCategoryMutation()

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data3,event)=>{
        let formData = new FormData()
            formData.append('title',data3.title)
            formData.append('symbol',data3.symbol)
            formData.append('description',data3.description)
            formData.append('currency',data3.currency)
            formData.append('icon',data3.icon[0])
        addCategory(formData)
    }

useEffect(()=>{
  if(data?.code == "SUCCESS"){
    setErr('')
    toast.success('Category Succesfully created !',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    })
  }else if(data?.status == "FAILURE"){
    setErr(data?.error)
    toast.error('Category Creation Failed !',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    })
  }
},[data])

  return (
    <div>
        <div className='formContainer'>
        <h4 className='center-text error'>{err}</h4>
          <form onSubmit={handleSubmit(onSubmit)} key={1} encType='multipart/form-data'>
            <Grid container columnSpacing={7}>
              <Grid item xl={6} lg={6} md={6}>
                <div>
                  <label htmlFor="title">Title</label>
                  <input type="text" {...register("title")}/>
                  {<span className='error'>{errors.title?.message}</span>}
                </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <div>
                  <label htmlFor="lastName">Symbol</label>
                  <input type="text" {...register("symbol")}/>
                  {<span className='error'>{errors.symbol?.message}</span>}
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
              <div>
                <label htmlFor="country">Description</label>
                <input type="text" {...register("description")}/>
                {<span className='error'>{errors.country?.message}</span>}
              </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <label>Currency</label>
                <select {...register("currency")} id="">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="XAF">XAF</option>
                </select>
              </Grid>
                <Grid item xl={5} lg={5} md={5}>
                <label>Icon</label>
                <input type="file" {...register("icon")}/>
              </Grid>
            </Grid>

            {
              isLoading?(
                <Spinner />
              ):(
                <Button fullWidth type="submit">Add Category</Button>
              )
            }
          </form>
        </div>
    </div>
  )
}

export default AddCategory
