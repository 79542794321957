import React from 'react'
import './Dashboard.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false
    },
  },
};

const labels = ['January', 'February', 'March'];
const data = {
  labels,
  datasets: [
    {
      label: 'Earnings',
      data: [100,200,500],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

function TheChart() {
  return <Bar options={options} data={data} />;
}





function Earnings() {
  return (
    <div className='Earnings-container'>
      <div className='Earnings-header'>
          <p>Your Earnings Overview</p>
      </div>
      <div className='Earnings-body'>
        <TheChart/>
      </div>
    </div>
  )
}

export default Earnings
