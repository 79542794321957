import React from 'react'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { faker } from '@faker-js/faker';
import {MdOpenInNew} from 'react-icons/md'

function createData(id,reporter,title,type){
  return {id,reporter,title,type}
}

const rows =()=>{
  let items=[];
  for(let i=0;i<=5;i++){
    items.push(createData(
      faker.datatype.uuid(),
      faker.internet.email(),
      faker.lorem.lines(2),
      faker.finance.transactionType()
      ))
  }

  return items;
}


function OpenTickets() {
  const [data,setData] = React.useState(rows())
  return (
    <div className='tableContainer'>
        <div className='tableHeader'>
<p style={{color:"white",backgroundColor:"#05c279",width:"fit-content",padding:'5px',borderRadius:"2px",fontSize:"20px",fontWeight:"bolder"}}>Open tickets</p>
        </div>
    <TableContainer>
        <TableHead>
            <TableRow>
                <TableCell><p>Reporter</p></TableCell>
                <TableCell><p>Title</p></TableCell>
                <TableCell><p>Type</p></TableCell>
                <TableCell><p>Action</p></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                data.map(row=>(
                    <TableRow key={row.title}>
                        <TableCell><p>{row.reporter}</p></TableCell>
                        <TableCell><p>{row.title}</p></TableCell>
                        <TableCell align='center'><p style={{color:'white',backgroundColor:"#ED2939",borderRadius:'2px',padding:"3px"}}>{row.type}</p></TableCell>
                        <TableCell>
                            <div className='flex-Arn'>
                                {/* <button>OPEN</button> */}
                                {/* <button>Delete</button> */}
                                <a href= {`open/${row.id}`}>
                                  <MdOpenInNew size={30} style={{color:'#4682B4'}}/>
                                </a>
                            </div>
                        </TableCell>
                    </TableRow>
                ))
             }
        </TableBody>
    </TableContainer>
    </div>
  )
}

export default OpenTickets
