import { toast } from 'react-toastify';
import { useDeleteCategoryMutation, useSingleCateQuery } from '../../api/endpoints/Category';
import { Spinner } from '../Other/Loading/Spinner';
import { Button,Modal } from '@mui/material';
import { useState,useEffect } from 'react';

const modalDiv = {
    position:"absolute",
    width:"450px",
    height:"fit-content",
    backgroundColor:"white",
    outline:"none",
    top:"10%",
    left:"40%",
    padding:"10px"
}

export const DeleteCategory =({isOpen,CategoryId,setDeleteModal}) =>{
    const {data,isLoading,error} = useSingleCateQuery(CategoryId)
    const [deleteCate, {data:delData,isLoading:delLoading}] = useDeleteCategoryMutation()
    const handleClose = () =>{
        setDeleteModal(prev=>!prev)
    }
    // console.log(CategoryId)
    const handleDelete =()=>{
        deleteCate(CategoryId)
    }
    useEffect(()=>{
        if(delData?.code == "SUCCESS"){
            toast.success('Category deleted with Success !',{
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
            window.location.reload();
          }else if(delData?.status == "FAILURE"){
            toast.error('Category deletion Failed !',{
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            })
          }
    },[delData])
    return(
        <Modal open={isOpen} onClose={handleClose}>
                <div style={modalDiv}>
                    {CategoryId != data?.result?.id?(
                        <Spinner />
                    ):(
                        <div>
                    <h3 className='error center-text'>Do you really want to delete this Category?</h3>
                    <p>Name : <b>{data?.result?.title}</b> </p>
                    <p>Created by : <b>{data?.result?.createdBy?.email}</b> </p>
                    <p>Product Count : <b>{data?.result?.products.length}</b> </p>
                    <div style={{margin:"10px 0px"}}>
                         <Button onClick={handleDelete} fullWidth variant='contained'>DELETE</Button>
                    </div>
                    </div>
                    )}
                </div>
        </Modal>
    )
}
