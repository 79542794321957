import { Navigate,Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";



export const CheckUserIsAuth = () =>{
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch()
    const checkAuth =()=>{
        const tokenExist = cookies?.token;
        const userDataExist = localStorage.getItem('userData')
        if(!tokenExist || tokenExist === null || tokenExist === '' || tokenExist == undefined ){
            localStorage.removeItem('userData')
            return false
        }
        if(!userDataExist || userDataExist === null || userDataExist === '' || userDataExist == undefined){
            removeCookie('token','',{secure:true})
            return false
        }
        
        return true;
    }

    const state = checkAuth()

    return(
        <>
            {state?(
                <Outlet />
                
            ):(
                <Navigate to={"/signin"} />
            )}
        </>
    )
}

export const CheckUserIsNotAuth=()=>{
    const [cookies, setCookie,removeCookie] = useCookies(['token']);
    const checkNotAuth =()=>{
        const tokenExist = cookies.token;
        const userDataExist = localStorage.getItem('userData')

        if(!tokenExist || tokenExist == null || tokenExist === '' || tokenExist === undefined){
            localStorage.removeItem('userData')
            return true
        }
        if(!userDataExist || userDataExist == null || userDataExist === '' || userDataExist === undefined){
            removeCookie('token','',{secure:true})
            return true
        }

        

        return false
    }
    return(
        <>
            {checkNotAuth()?(
                <Outlet />
            ):(
                <Navigate to={'/Dashboard'} />
            )}
        </>
    )
}
