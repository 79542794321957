import React from 'react'
import '../../index.css'
import { Button } from '@mui/material'
import * as yup from "yup" 
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {useCookies} from 'react-cookie'
import { setCredentials } from '../../api/authSlice';
import { useLoginMutation } from '../../api/endpoints/Auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Spinner} from "../Other/Loading/Spinner"


const schema = yup.object({
  email: yup.string().required('*Enter Email'),
  password: yup.string().required('*Enter Password'),
}).required()

function SignIn() {
  const [errMsg,setErrorMessage] = React.useState("");
   const [login,{isLoading}]=useLoginMutation()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies(['token']);
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (formData,event)=>{
    event.preventDefault()
    try{
       const results = await login(formData).unwrap()

      if(results.status === 'FAILURE'){
        setErrorMessage(results.error)
      }
      else if(results.status === 'SUCCESS'){
          setCookie('token',results.result.access_token,{secure:true})
          dispatch(setCredentials(results.result.access_token))
          navigate('/Dashboard')
      }
      
    }catch(e){
        console.log(e)
    }
    
  }

  return (
    <div>
        <div className='formContainer'>
          <h4 className='center-text error'>{errMsg}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor='email'>Email</label>
                <input name='email' type='email' {...register('email')} />
                {<span className='error'>{errors.email?.message}</span>}

                <label htmlFor='password'>Password</label>
                <input name='password' type='password' {...register('password')} />
                {<span className='error'>{errors.email?.message}</span>}

                {
                  isLoading?(
                    <Spinner />
                  ):(
                    <Button type='submit' variant='outlined' >Submit</Button>
                  )
                }
            </form>
        </div>
    </div>
  )
}

export default SignIn
