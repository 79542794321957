import React, { useEffect,useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useGetSingleUserQuery } from '../../api/endpoints/User'
import { Spinner, formatDate } from '../Other/Loading/Spinner'
import { Grid} from '@mui/material'
import { toast } from 'react-toastify'

function SingleUser() {
    const navigate = useNavigate()
    const {userId} = useParams()
    const {data,isLoading,error} = useGetSingleUserQuery(userId);
    let [user,setUser] = useState({});

    useEffect(()=>{
        if(data?.code == "SUCCESS"){ 
            setUser(data?.result)
        }else if(data?.code == "USER_NOT_FOUND"){
            toast.error('User Not Found!',{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
              })
              navigate("/user/all")
        }
    },[data])

  return (
    <div>
        {isLoading?(<Spinner />):(
            <div className='singleUserContainer'>
                <div className='singleUserItem'>
                    <h3 align="center" style={{color:"#05c279"}}>User Details</h3>
                    <Grid container spacing={1}>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Firstname</label>
                            <p>{user.firstName}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='lastName'>Lastname</label>
                            <p>{user.lastName}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='email'>Email</label>
                            <p>{user.email}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='phone'>Telephone</label>
                            <p>{user.phone}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Country</label>
                            <p>{user.country}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>AddressS</label>
                            <p>{user.address}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Account Created</label>
                            <p>{formatDate(user?.createdAt?? "2029-05-07T17:46:52.487Z")}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Date of Birth</label>
                            <p>{formatDate(user?.birthday?? "2029-05-07T17:46:52.487Z")}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Privilege</label>
                            <p>{user?.privilege?.type}</p>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6}>
                            <label htmlFor='firstName'>Last connected</label>
                            <p>{formatDate(user?.lastConnexion?? "2029-05-07T17:46:52.487Z")}</p>
                        </Grid>                    
                    </Grid>
                </div>
            </div>
        )}
    </div>
  )
}

export default SingleUser
