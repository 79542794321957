import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { faker } from '@faker-js/faker';

function createData(customer,amount,plan_name,created_time){
  return {customer,amount,plan_name,created_time}
}

const rows =()=>{
  let items=[];
  for(let i=0;i<=30;i++){
    items.push(createData(
      faker.name.fullName(),
      faker.finance.amount(500,3000,2,'$'),
      faker.commerce.department(),
      faker.date.future().toDateString()
      ))
  }

  return items;
}

function LatestTransactions() {
  return (
    <div className='Latest-data-Container'>
        <div className='Earnings-header'>
          <p>Latest Ads</p>
        </div>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Customer</b></TableCell>
                        <TableCell><b>Amount</b></TableCell>
                        <TableCell align="left"><b>Plan Name</b></TableCell>
                        <TableCell align="left"><b>Date Created</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows().map(row=>
                      <TableRow key={row.customer}>
                        <TableCell>
                          <span>
                            {row.customer}
                          </span>
                        </TableCell>
                        <TableCell>
                            {row.amount}
                        </TableCell>
                        <TableCell>
                          <span style={{color:"white",backgroundColor:'#05c279',padding:'3px',borderRadius:'3px'}}>
                            {row.plan_name}
                          </span>
                        </TableCell>
                        <TableCell>
                            {row.created_time}
                        </TableCell>
                      </TableRow>
                    )
                  }
          {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span>
                    {row.name}
                </span>
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align="right">
                <span>
                    {row.since}
                </span>
              </TableCell>
            </TableRow>
          ))} */}
        </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}

export default LatestTransactions
