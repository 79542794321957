import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(title,price,status){
    return {title,price,status}
}

const rows = [
    createData('demo sel','$6,790.00','Active'),
    createData('The quick brown fox jumps','$6,790.00','Active'),
    createData('Sweet juices','$6,790.00','Pending'),
    createData('Iphone 11 pro max','$6,790.00','Active'),
    createData('PS4 Pro','$6,790.00','Pending'),
    createData('10 gas bottles','$6,790.00','Refused'),
    createData('64-inch smart TV (Samsung)','$6,790.00','Active'),
    createData('React dev','$6,790.00','Pending'),
    createData('2 room house with Toilet','$6,790.00','Active')
]

function LatestAds() {
  return (
    <div className='Latest-data-Container'>
        <div className='Earnings-header'>
          <p>Latest Ads</p>
        </div>
        <TableContainer >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Title</b></TableCell>
            <TableCell align="right"><b>Price</b></TableCell>
            <TableCell align="right"><b>Status</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span>
                    {row.title}
                </span>
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">
                <span>
                    {row.status}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default LatestAds
