import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name,email,since){
    return {name,email,since}
}

const rows = [
    createData('demo sel','test@gmail.com','1 week'),
    createData('The quick brown fox jumps','test@gmail.com','1 week'),
    createData('Sweet juices','test@gmail.com','1 week'),
    createData('Iphone 11 pro max','test@gmail.com','1 week'),
    createData('PS4 Pro','test@gmail.com','1 week'),
    createData('10 gas bottles','test@gmail.com','1 week'),
    createData('64-inch smart TV (Samsung)','test@gmail.com','1 week'),
    createData('React dev','test@gmail.com','1 week'),
    createData('2 room house with Toilet','test@gmail.com','1 week')
]
function LatestRegisteredUsers() {
  return (
    <div className='Latest-data-Container'>
        <div className='Earnings-header'>
          <p>Latest Ads</p>
        </div>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Email</b></TableCell>
                        <TableCell align="left"><b>Since</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <span>
                    {row.name}
                </span>
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align="right">
                <span>
                    {row.since}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}

export default LatestRegisteredUsers
