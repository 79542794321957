import './menu.css'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Link from '../Other/Link/Link'
import {CiMoneyBill} from 'react-icons/ci'
import {FaUsers} from 'react-icons/fa'
import {BsFillCreditCardFill} from 'react-icons/bs'
import {RiLoginBoxFill} from 'react-icons/ri'
import {HiOutlineDocument} from 'react-icons/hi'
import {BsSpeedometer2,BsTicket,BsFillTicketFill,BsTicketDetailedFill,BsTicketDetailed} from 'react-icons/bs'
import {MdPendingActions,MdToday,MdDiscount,MdVerifiedUser} from 'react-icons/md'
import {GiConfirmed} from 'react-icons/gi'
import {BiCategoryAlt} from 'react-icons/bi'
import {TbBrandAdobe,TbArrowsExchange2} from 'react-icons/tb'
import {AiOutlineUserAdd} from 'react-icons/ai'
import {GoUnverified} from 'react-icons/go'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../api/authSlice'

function Menu() {


  const user = useSelector(selectCurrentUser)
  return (
    <>
    <div className='left-menu-nav'>
      {user?.email?.length>5 ?(
        <LoggedIn />
      ):
      (
        <NotLoggedIn />
      )}
    </div>
    </>
  )
}

const NotLoggedIn = ()=>{
  return(
    <div className='left-menu-nav-1' style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
      <RiLoginBoxFill size={80} color='white'/>
    </div>
  )
}

const LoggedIn = () =>{
  const style ={
    fontSize:'20px',
    margin:'0px',
    padding:'0px'
  }
  return (
    <div className='left-menu-nav-1' style={{height:'70vh',overflowY:'scroll',overflowX:'hidden'}}>
        <div>
          <p>Dashboard</p>
          <Link text='Dashboard' path='/dashboard' icon={<BsSpeedometer2 style={style}/>}/>
        </div>

        <div>
          <p>Order</p>
          <Link text='All Categories' path='category' icon={<CiMoneyBill style={style}/>}/>
          <Link text='Add Category' path='category/add' icon={<CiMoneyBill style={style}/>}/>

          
          <Link text='Seller Report' path='SellerReport' icon={<FaUsers style={style}/>}/>
          <Link text='Customer Report' path='CustomerReport' icon={<HiOutlineDocument style={style}/>}/>
        </div>

        <div>
          <p>Tickets</p>
            <Link text='Opened tickets' path='Tickets/open' icon={<BsTicket style={style}/>}/>
            <Link text='Closed tickets' path='Tickets/closed' icon={<BsFillTicketFill style={style}/>}/>
            <Link text='Solved tickets' path='Tickets/solved' icon={<BsTicketDetailedFill style={style}/>}/>
            <Link text='Pending tickets' path='Tickets/pending' icon={<BsTicketDetailed style={style}/>}/>
        </div>
        <div>
          <p>Payout Statistics</p>
            <Link text='Pending Requests' path='payout/pending' icon={<MdPendingActions style={style}/>}/>
            <Link text='Today Payouts' path='payout/today' icon={<MdToday style={style}/>}/>
            <Link text='Month Payout' path='payout/monthAccepted' icon={<GiConfirmed style={style}/>}/>
            <Link text='Month Charges' path='payout/monthCharges' icon={<MdDiscount style={style}/>}/>
        </div>
        <div>
          <p>Transactions</p>
          <Link text='All Listings' path='transaction/all' icon={<TbArrowsExchange2 style={style}/>}/>
          <Link text='Categories' path='transaction/category' icon={<BiCategoryAlt style={style}/>}/>
          <Link text='Brands' path='transaction/brand' icon={<TbBrandAdobe style={style}/>}/>

        </div>
        <div>
          <p>User Management</p>
          <Link text='Add User' path='user/add' icon={<AiOutlineUserAdd style={style}/>}/>
          <Link text='All Users' path='user/all' icon={<FaUsers style={style}/>}/>
          <Link text='Search User' path='user/search' icon={<MdVerifiedUser style={style}/>}/>
        </div>
        </div>
  )
}

export default Menu
