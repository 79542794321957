import { NavLink } from "react-router-dom";
import './link.css'
import { FaBeer } from 'react-icons/fa';

import React from 'react'

function Link({path,text='deafult',icon}) {
  return (
    <div className="linkContainer" >
            <NavLink to={path} 
            className={({ isActive, isPending }) =>
            isPending ? "pendinglink" : isActive ? "activelink" : "defaultLink"}>
                {icon}
                {text}
            </NavLink>
    </div>
  )
}

export default Link
