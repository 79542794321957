import './layout.css'

import React from 'react'

function Footer() {
  return (
<div>
  <div className='footer-div'>
    <div className='footer-div-left'>
      <p><b>Copyright 2023  <span style={{color:'#05c279'}}>Fairlyx Admin .</span></b>All Rights Reserved.</p>
    </div>
    <div className='footer-div-right'>
      <p>Version</p>
    </div>
  </div>
</div>
  )
}

export default Footer
