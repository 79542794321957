import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './report.css'
import { faker } from '@faker-js/faker';

function createData(reporter,reported,reason){
  return {reporter,reported,reason}
}

const rows =()=>{
  let items=[];
  for(let i=0;i<=30;i++){
    items.push(createData(
      faker.name.fullName(),
      faker.name.fullName(),
      faker.lorem.lines(3)
      ))
  }

  return items;
}
const data = rows();

function CustomerReport() {
  return (
    <div className='tableContainer'>
        <div className='tableHeader'>
<p>Reports from Customers</p>
        </div>
    <TableContainer>
        <TableHead>
            <TableRow>
                <TableCell><p>Reporter</p></TableCell>
                <TableCell><p>Reported</p></TableCell>
                <TableCell><p>Reason</p></TableCell>
                <TableCell><p>Action</p></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                data.map(row=>(
                    <TableRow>
                        <TableCell><p>{row.reporter}</p></TableCell>
                        <TableCell><p>{row.reported}</p></TableCell>
                        <TableCell><p>{row.reason}</p></TableCell>
                        <TableCell>
                            <div className='flex-Arn'>
                                <button>View</button>
                                <button>Delete</button>
                            </div>
                        </TableCell>
                    </TableRow>
                ))
             }
        </TableBody>
    </TableContainer>
    </div>
  )
}

export default CustomerReport
