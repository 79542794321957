import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css'
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { store } from './api/store/store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <BrowserRouter>
    <CookiesProvider>
      <Provider store={store} >
        <ToastContainer />
        <App/>
      </Provider>
    </CookiesProvider>
  </BrowserRouter>
);

