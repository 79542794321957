import {apiSlice} from "../apiSlice"

const categoryEndpoints = apiSlice.injectEndpoints({
    endpoints:builder=>({
        allCategories:builder.query({
            providesTags:['Category'],
            query:()=>"https://dev.catalog.api.fairlyx.com/categories"
        }),
        addCategory:builder.mutation({
            invalidatesTags:['Category'],
            query:data =>({
                url:"https://dev.catalog.api.fairlyx.com/categories/add",
                method:"POST",
                body:data
            })
        }),
        editCategory:builder.mutation({
            invalidatesTags:['Category'],
            query:data =>({
                url:`https://dev.catalog.api.fairlyx.com/categories/edit/${data?.categoryId}`,
                method:"PATCH",
                body:data
            })
        }),
        singleCate:builder.query({
            invalidatesTags:['Category'],
            query:(cateId)=>`https://dev.catalog.api.fairlyx.com/categories/${cateId}`
        }),
        deleteCategory:builder.mutation({
            invalidatesTags:['Category'],
            query:(cateId) =>({
                url:`https://dev.catalog.api.fairlyx.com/categories/delete/${cateId}`,
                method:"DELETE",
            })
        }),
    })
})


export const {
    useAllCategoriesQuery,
    useAddCategoryMutation,
    useEditCategoryMutation,
    useSingleCateQuery,
    useDeleteCategoryMutation
} = categoryEndpoints
