import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { useGetUsersQuery } from '../../api/endpoints/User'
import { useState } from 'react'
import { useEffect } from 'react'
import {FaExpandArrowsAlt} from 'react-icons/fa'
import { Spinner } from '../Other/Loading/Spinner'
import { Link } from 'react-router-dom'

function AllUsers() {
  const [allUsers,setUsers] = useState([])
  const [type,setType] = useState('subscribers')

  const {data,isLoading,error} = useGetUsersQuery(type)

  useEffect(()=>{
    if(data?.status == "SUCCESS"){
        setUsers(data?.result.items)
    }
  },[data])

  return (
    <div>
      <div className='tableContainer'>
      <p> Select type </p>
      <div style={{width:"200px"}}>
        <select name="" id="" onChange={e=>setType(e.target.value)} value={type}>
          <option value="admins">Admins (ADMIN ,SUPER_ADMIN)</option>
          <option value="subscribers">Subscribers (CLIENT, VENDOR)</option>
        </select>
      </div>
      <TableContainer>
            <Table>
                <TableHead >
                    <TableRow>
                        <TableCell><p>ID</p></TableCell>
                        <TableCell><p>Email</p></TableCell>
                        <TableCell><p>First name</p></TableCell>
                        <TableCell><p>Last name</p></TableCell>
                        <TableCell><p>Role</p></TableCell>
                        <TableCell><p>Priviledge</p></TableCell>
                        <TableCell><p>View</p></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading?(
                      <p style={{with:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Spinner />
                      </p>
                    ):(
                      allUsers.map((user,index)=>(
                        <TableRow key={index}>
                            <TableCell><p>{user.id}</p></TableCell>
                            <TableCell><p>{user.email}</p></TableCell>
                            <TableCell><p>{user.firstName}</p></TableCell>
                            <TableCell><p>{user.lastName}</p></TableCell>
                            <TableCell><p>{user.role}</p></TableCell>
                            <TableCell><p>{user?.privilege?.name}</p></TableCell>
                            <TableCell>
                              <Link to={`./${user.id}`}><FaExpandArrowsAlt color='#05c279' size={20}/></Link>
                            </TableCell>
                        </TableRow>
                    ))
                    )}
                  </TableBody>
            </Table>
        </TableContainer>
    </div>
  </div>
  )
}

export default AllUsers
