import { Routes,Route } from "react-router-dom";
import React from 'react'
import ClosedTickets from "./ClosedTickets";
import OpenTickets from "./OpenedTIckets";
import SolvedTickets from "./SolvedTicets";
import PendingTickets from "./PendingTickets";
import SingleOpenTicket from "./SingleOpenTicket";


function Tickets() {
  return (
    <Routes>
        <Route path="/open" element={<OpenTickets/>}/>
        <Route path="/open/:ticketId" element={<SingleOpenTicket/>}/>
        <Route path="/closed" element={<ClosedTickets/>}/>
        <Route path="/pending" element={<PendingTickets/>}/>
        <Route path="/solved" element={<SolvedTickets/>}/>
    </Routes>
  )
}

export default Tickets
